import { TShop } from '@/common/models';
import { TCommonPaginate, TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetShopsParams = unknown;

export type TGetShopsMaterials = {
  params?: TGetShopsParams;
};

export type TGetShopsResponse = TCommonResponse & {
  data: TCommonPaginate & {
    content: TShop[];
  };
};

// FUNCTION

export const getShops = async ({ params }: TGetShopsMaterials): Promise<TGetShopsResponse> => {
  const response = await ApiService.get(`/api-public/shops`, { params });
  return response?.data;
};
