import { all, fork } from 'redux-saga/effects';

import appointmentSaga from './appointment';
import authSaga from './auth';
import categorySaga from './category';
import memberSaga from './member';
import settingSaga from './setting';
import shopSaga from './shop';
import technicianSaga from './technician';

const rootSaga = function* root(): Generator {
  yield all([
    fork(appointmentSaga),
    fork(authSaga),
    fork(categorySaga),
    fork(memberSaga),
    fork(settingSaga),
    fork(shopSaga),
    fork(technicianSaga),
  ]);
};

export default rootSaga;
