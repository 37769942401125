import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createAppointmentAction } from '@/redux/actions';
import { createAppointment, TCreateAppointmentResponse } from '@/services/api';

// FUNCTION

export function* createAppointmentSaga(action: ActionType<typeof createAppointmentAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createAppointment, materials);
    const createAppointmentResponse: TCreateAppointmentResponse = response as TCreateAppointmentResponse;
    yield put(createAppointmentAction.success(createAppointmentResponse));
    successCallback?.(createAppointmentResponse);
  } catch (err) {
    yield put(createAppointmentAction.failure(err));
    failedCallback?.(err);
  }
}
