import { TMember } from '@/common/models';
import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetMemberByMobilePaths = {
  mobile: string | number;
};
export type TGetMemberByMobileParams = unknown;

export type TGetMemberByMobileMaterials = {
  paths?: TGetMemberByMobilePaths;
  params?: TGetMemberByMobileParams;
};

export type TGetMemberByMobileResponse = TCommonResponse & {
  data: TMember;
};

// FUNCTION

export const getMemberByMobile = async ({
  paths,
  params,
}: TGetMemberByMobileMaterials): Promise<TGetMemberByMobileResponse> => {
  const response = await ApiService.get(`/api-public/members/name/${paths?.mobile}`, { params });
  return response?.data;
};
