import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getBranchesByShopAction } from '@/redux/actions';
import { getBranchesByShop, TGetBranchesByShopResponse } from '@/services/api';

// FUNCTION

export function* getBranchesByShopSaga(action: ActionType<typeof getBranchesByShopAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getBranchesByShop, materials);
    const getBranchesByShopResponse: TGetBranchesByShopResponse = response as TGetBranchesByShopResponse;
    yield put(getBranchesByShopAction.success(getBranchesByShopResponse));
    successCallback?.(getBranchesByShopResponse);
  } catch (err) {
    yield put(getBranchesByShopAction.failure(err));
    failedCallback?.(err);
  }
}
