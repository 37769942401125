import { createActionCreator } from 'deox';

import { TCreateAppointmentMaterials, TCreateAppointmentResponse } from '@/services/api/appointment/create-appointment';

// CONSTANTS

export enum ECreateAppointmentAction {
  CREATE_APPOINTMENT = 'CREATE_APPOINTMENT',
  CREATE_APPOINTMENT_REQUEST = 'CREATE_APPOINTMENT_REQUEST',
  CREATE_APPOINTMENT_SUCCESS = 'CREATE_APPOINTMENT_SUCCESS',
  CREATE_APPOINTMENT_FAILED = 'CREATE_APPOINTMENT_FAILED',
}

// TYPES

export type TCreateAppointmentRequest = {
  type: ECreateAppointmentAction.CREATE_APPOINTMENT_REQUEST;
  payload: {
    materials: TCreateAppointmentMaterials;
    successCallback?: (response: TCreateAppointmentResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateAppointmentSuccess = {
  type: ECreateAppointmentAction.CREATE_APPOINTMENT_SUCCESS;
  payload: { response: TCreateAppointmentResponse };
};

export type TCreateAppointmentFailed = { type: ECreateAppointmentAction.CREATE_APPOINTMENT_FAILED };

// FUNCTION

export const createAppointmentAction = {
  request: createActionCreator(
    ECreateAppointmentAction.CREATE_APPOINTMENT_REQUEST,
    (resolve) =>
      (
        materials: TCreateAppointmentMaterials,
        successCallback?: (response: TCreateAppointmentResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateAppointmentRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateAppointmentAction.CREATE_APPOINTMENT_SUCCESS,
    (resolve) =>
      (response: TCreateAppointmentResponse): TCreateAppointmentSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateAppointmentAction.CREATE_APPOINTMENT_FAILED,
    (resolve) =>
      (error: unknown): TCreateAppointmentFailed =>
        resolve({ error }),
  ),
};
