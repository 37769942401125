import ApiService from '@/services/api';

// TYPES

export type TCreateAppointmentParams = unknown;
export type TCreateAppointmentBody = unknown;

export type TCreateAppointmentMaterials = {
  params?: TCreateAppointmentParams;
  body?: TCreateAppointmentBody;
};

export type TCreateAppointmentResponse = unknown;

// FUNCTION

export const createAppointment = async ({
  params,
  body,
}: TCreateAppointmentMaterials): Promise<TCreateAppointmentResponse> => {
  const response = await ApiService.post(`/api-public/appointments`, body, { params });
  return response?.data;
};
