import { createActionCreator } from 'deox';

import { TGetMemberByMobileMaterials, TGetMemberByMobileResponse } from '@/services/api/member/get-member-by-mobile';

// CONSTANTS

export enum EGetMemberByMobileAction {
  GET_MEMBER_BY_MOBILE = 'GET_MEMBER_BY_MOBILE',
  GET_MEMBER_BY_MOBILE_REQUEST = 'GET_MEMBER_BY_MOBILE_REQUEST',
  GET_MEMBER_BY_MOBILE_SUCCESS = 'GET_MEMBER_BY_MOBILE_SUCCESS',
  GET_MEMBER_BY_MOBILE_FAILED = 'GET_MEMBER_BY_MOBILE_FAILED',
}

// TYPES

export type TGetMemberByMobileRequest = {
  type: EGetMemberByMobileAction.GET_MEMBER_BY_MOBILE_REQUEST;
  payload: {
    materials: TGetMemberByMobileMaterials;
    successCallback?: (response: TGetMemberByMobileResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetMemberByMobileSuccess = {
  type: EGetMemberByMobileAction.GET_MEMBER_BY_MOBILE_SUCCESS;
  payload: { response: TGetMemberByMobileResponse };
};

export type TGetMemberByMobileFailed = { type: EGetMemberByMobileAction.GET_MEMBER_BY_MOBILE_FAILED };

// FUNCTION

export const getMemberByMobileAction = {
  request: createActionCreator(
    EGetMemberByMobileAction.GET_MEMBER_BY_MOBILE_REQUEST,
    (resolve) =>
      (
        materials: TGetMemberByMobileMaterials,
        successCallback?: (response: TGetMemberByMobileResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetMemberByMobileRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetMemberByMobileAction.GET_MEMBER_BY_MOBILE_SUCCESS,
    (resolve) =>
      (response: TGetMemberByMobileResponse): TGetMemberByMobileSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetMemberByMobileAction.GET_MEMBER_BY_MOBILE_FAILED,
    (resolve) =>
      (error: unknown): TGetMemberByMobileFailed =>
        resolve({ error }),
  ),
};
