import env from '@/env';
import AuthorizedInstance from '@/services/authorized-api';

const ApiService = AuthorizedInstance(env.api.baseUrl.service);

export default ApiService;
export * from './auth';

export * from './technician';
export * from './category';
export * from './appointment';
export * from './setting';
export * from './shop';
export * from './member';
