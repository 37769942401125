import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getTechniciansAction } from '@/redux/actions';
import { getTechnicians, TGetTechniciansResponse } from '@/services/api';

// FUNCTION

export function* getTechniciansSaga(action: ActionType<typeof getTechniciansAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getTechnicians, materials);
    const getTechniciansResponse: TGetTechniciansResponse = response as TGetTechniciansResponse;
    yield put(getTechniciansAction.success(getTechniciansResponse));
    successCallback?.(getTechniciansResponse);
  } catch (err) {
    yield put(getTechniciansAction.failure(err));
    failedCallback?.(err);
  }
}
