import { all, takeLatest } from 'redux-saga/effects';

import { createAppointmentAction, getAppointmentsAction } from '@/redux/actions';

import { createAppointmentSaga } from './create-appointment';
import { getAppointmentsSaga } from './get-appointments';

export default function* root(): Generator {
  yield all([
    takeLatest(createAppointmentAction.request.type, createAppointmentSaga),
    takeLatest(getAppointmentsAction.request.type, getAppointmentsSaga),
  ]);
}
