import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getMemberByMobileAction } from '@/redux/actions';
import { getMemberByMobile, TGetMemberByMobileResponse } from '@/services/api';

// FUNCTION

export function* getMemberByMobileSaga(action: ActionType<typeof getMemberByMobileAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getMemberByMobile, materials);
    const getMemberByMobileResponse: TGetMemberByMobileResponse = response as TGetMemberByMobileResponse;
    yield put(getMemberByMobileAction.success(getMemberByMobileResponse));
    successCallback?.(getMemberByMobileResponse);
  } catch (err) {
    yield put(getMemberByMobileAction.failure(err));
    failedCallback?.(err);
  }
}
