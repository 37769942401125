import { TAppointmentState } from '@/redux/reducers/appointment';
import { TGetAppointmentsSuccess } from '@/redux/actions/appointment';

export const getAppointmentsUpdateState = (
  state: TAppointmentState,
  action: TGetAppointmentsSuccess,
): TAppointmentState => ({
  ...state,
  getAppointmentsResponse: action.payload.response,
});
