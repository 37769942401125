import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from '@reach/router';
import classNames from 'classnames';

import { TRootState } from '@/redux/reducers';

import { dataHeaderMenu } from './Header.data';
import { THeaderProps } from './Header.types.d';
import './Header.scss';

const Header: React.FC<THeaderProps> = () => {
  const { pathname } = useLocation();
  const settingsState = useSelector((state: TRootState) => state.settingReducer.getSettingsResponse)?.data;

  return (
    <header className="Header">
      <div className="Header-wrapper">
        <div className="Header-menu">
          <div className="Header-menu-wrapper flex items-center justify-evenly">
            {dataHeaderMenu.map((item) => (
              <Link
                to={item.link}
                key={item.id}
                className={classNames('Header-menu-item', { active: item.activePaths.includes(pathname) })}
              >
                {item.title}
              </Link>
            ))}
          </div>
        </div>
        {/* <div className="Header-logo">
          <img src={Logo} alt="" />
        </div> */}
        <div className="Header-name text-center">{settingsState?.business?.name}</div>
      </div>
    </header>
  );
};

export default Header;
