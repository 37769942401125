import { TAppointment } from '@/common/models';
import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetAppointmentsParams = {
  appointmentStatuses?: string;
  fromDateTime?: number;
  toDateTime?: number;
};

export type TGetAppointmentsMaterials = {
  params?: TGetAppointmentsParams;
};

export type TGetAppointmentsResponse = TCommonResponse & { data: TAppointment[] };

// FUNCTION

export const getAppointments = async ({ params }: TGetAppointmentsMaterials): Promise<TGetAppointmentsResponse> => {
  const response = await ApiService.get(`/api-public/appointments`, { params });
  return response?.data;
};
