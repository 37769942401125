import { createActionCreator } from 'deox';

import { TGetBranchesByShopMaterials, TGetBranchesByShopResponse } from '@/services/api/shop/get-branches-by-shop';

// CONSTANTS

export enum EGetBranchesByShopAction {
  GET_BRANCHES_BY_SHOP = 'GET_BRANCHES_BY_SHOP',
  GET_BRANCHES_BY_SHOP_REQUEST = 'GET_BRANCHES_BY_SHOP_REQUEST',
  GET_BRANCHES_BY_SHOP_SUCCESS = 'GET_BRANCHES_BY_SHOP_SUCCESS',
  GET_BRANCHES_BY_SHOP_FAILED = 'GET_BRANCHES_BY_SHOP_FAILED',
}

// TYPES

export type TGetBranchesByShopRequest = {
  type: EGetBranchesByShopAction.GET_BRANCHES_BY_SHOP_REQUEST;
  payload: {
    materials: TGetBranchesByShopMaterials;
    successCallback?: (response: TGetBranchesByShopResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetBranchesByShopSuccess = {
  type: EGetBranchesByShopAction.GET_BRANCHES_BY_SHOP_SUCCESS;
  payload: { response: TGetBranchesByShopResponse };
};

export type TGetBranchesByShopFailed = { type: EGetBranchesByShopAction.GET_BRANCHES_BY_SHOP_FAILED };

// FUNCTION

export const getBranchesByShopAction = {
  request: createActionCreator(
    EGetBranchesByShopAction.GET_BRANCHES_BY_SHOP_REQUEST,
    (resolve) =>
      (
        materials: TGetBranchesByShopMaterials,
        successCallback?: (response: TGetBranchesByShopResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetBranchesByShopRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetBranchesByShopAction.GET_BRANCHES_BY_SHOP_SUCCESS,
    (resolve) =>
      (response: TGetBranchesByShopResponse): TGetBranchesByShopSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetBranchesByShopAction.GET_BRANCHES_BY_SHOP_FAILED,
    (resolve) =>
      (error: unknown): TGetBranchesByShopFailed =>
        resolve({ error }),
  ),
};
