import { TAppointmentState } from '@/redux/reducers/appointment';
import { TCreateAppointmentSuccess } from '@/redux/actions/appointment';

export const createAppointmentUpdateState = (
  state: TAppointmentState,
  action: TCreateAppointmentSuccess,
): TAppointmentState => ({
  ...state,
  createAppointmentResponse: action.payload.response,
});
