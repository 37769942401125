import { TTechnicianState } from '@/redux/reducers/technician';
import { TGetTechniciansSuccess } from '@/redux/actions/technician';

export const getTechniciansUpdateState = (
  state: TTechnicianState,
  action: TGetTechniciansSuccess,
): TTechnicianState => ({
  ...state,
  getTechniciansResponse: action.payload.response,
});
