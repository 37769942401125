import { createReducer } from 'deox';

import { TGetTechniciansResponse } from '@/services/api/technician';
import { getTechniciansAction } from '@/redux/actions';
import { getTechniciansUpdateState } from './get-technicians';

export type TTechnicianState = {
  getTechniciansResponse?: TGetTechniciansResponse;
};

const initialState: TTechnicianState = {
  getTechniciansResponse: undefined,
};

const TechnicianReducer = createReducer(initialState, (handleAction) => [
  handleAction(getTechniciansAction.success, getTechniciansUpdateState),
]);

export default TechnicianReducer;
