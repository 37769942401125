import { createReducer } from 'deox';

import { TGetMemberByMobileResponse } from '@/services/api/member';
import { getMemberByMobileAction } from '@/redux/actions';
import { getMemberByMobileUpdateState } from './get-member-by-mobile';

export type TMemberState = {
  getMemberByMobileResponse?: TGetMemberByMobileResponse;
};

const initialState: TMemberState = {
  getMemberByMobileResponse: undefined,
};

const MemberReducer = createReducer(initialState, (handleAction) => [
  handleAction(getMemberByMobileAction.success, getMemberByMobileUpdateState),
]);

export default MemberReducer;
