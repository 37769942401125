import ApiService from '@/services/api';

// TYPES

export type TGetTechniciansParams = unknown;

export type TGetTechniciansMaterials = {
  params?: TGetTechniciansParams;
};

export type TGetTechniciansResponse = unknown;

// FUNCTION

export const getTechnicians = async ({ params }: TGetTechniciansMaterials): Promise<TGetTechniciansResponse> => {
  const response = await ApiService.get(`/api-public/technicians`, { params });
  return response?.data;
};
