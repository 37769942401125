import { createActionCreator } from 'deox';

import { TGetShopsMaterials, TGetShopsResponse } from '@/services/api/shop/get-shops';

// CONSTANTS

export enum EGetShopsAction {
  GET_SHOPS = 'GET_SHOPS',
  GET_SHOPS_REQUEST = 'GET_SHOPS_REQUEST',
  GET_SHOPS_SUCCESS = 'GET_SHOPS_SUCCESS',
  GET_SHOPS_FAILED = 'GET_SHOPS_FAILED',
}

// TYPES

export type TGetShopsRequest = {
  type: EGetShopsAction.GET_SHOPS_REQUEST;
  payload: {
    materials: TGetShopsMaterials;
    successCallback?: (response: TGetShopsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetShopsSuccess = {
  type: EGetShopsAction.GET_SHOPS_SUCCESS;
  payload: { response: TGetShopsResponse };
};

export type TGetShopsFailed = { type: EGetShopsAction.GET_SHOPS_FAILED };

// FUNCTION

export const getShopsAction = {
  request: createActionCreator(
    EGetShopsAction.GET_SHOPS_REQUEST,
    (resolve) =>
      (
        materials: TGetShopsMaterials,
        successCallback?: (response: TGetShopsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetShopsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetShopsAction.GET_SHOPS_SUCCESS,
    (resolve) =>
      (response: TGetShopsResponse): TGetShopsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetShopsAction.GET_SHOPS_FAILED,
    (resolve) =>
      (error: unknown): TGetShopsFailed =>
        resolve({ error }),
  ),
};
