import { TBranch } from '@/common/models';
import { TCommonResponse, TCommonPaginate } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetBranchesByShopPaths = {
  id: string | number;
};
export type TGetBranchesByShopParams = unknown;

export type TGetBranchesByShopMaterials = {
  paths?: TGetBranchesByShopPaths;
  params?: TGetBranchesByShopParams;
};

export type TGetBranchesByShopResponse = TCommonResponse & {
  data: TCommonPaginate & {
    content: TBranch[];
  };
};

// FUNCTION

export const getBranchesByShop = async ({
  paths,
  params,
}: TGetBranchesByShopMaterials): Promise<TGetBranchesByShopResponse> => {
  const response = await ApiService.get(`/api-public/shops/${paths?.id}/shop-branches`, { params });
  return response?.data;
};
