import { createActionCreator } from 'deox';

import { TGetAppointmentsMaterials, TGetAppointmentsResponse } from '@/services/api/appointment/get-appointments';

// CONSTANTS

export enum EGetAppointmentsAction {
  GET_APPOINTMENTS = 'GET_APPOINTMENTS',
  GET_APPOINTMENTS_REQUEST = 'GET_APPOINTMENTS_REQUEST',
  GET_APPOINTMENTS_SUCCESS = 'GET_APPOINTMENTS_SUCCESS',
  GET_APPOINTMENTS_FAILED = 'GET_APPOINTMENTS_FAILED',
}

// TYPES

export type TGetAppointmentsRequest = {
  type: EGetAppointmentsAction.GET_APPOINTMENTS_REQUEST;
  payload: {
    materials: TGetAppointmentsMaterials;
    successCallback?: (response: TGetAppointmentsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetAppointmentsSuccess = {
  type: EGetAppointmentsAction.GET_APPOINTMENTS_SUCCESS;
  payload: { response: TGetAppointmentsResponse };
};

export type TGetAppointmentsFailed = { type: EGetAppointmentsAction.GET_APPOINTMENTS_FAILED };

// FUNCTION

export const getAppointmentsAction = {
  request: createActionCreator(
    EGetAppointmentsAction.GET_APPOINTMENTS_REQUEST,
    (resolve) =>
      (
        materials: TGetAppointmentsMaterials,
        successCallback?: (response: TGetAppointmentsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetAppointmentsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetAppointmentsAction.GET_APPOINTMENTS_SUCCESS,
    (resolve) =>
      (response: TGetAppointmentsResponse): TGetAppointmentsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetAppointmentsAction.GET_APPOINTMENTS_FAILED,
    (resolve) =>
      (error: unknown): TGetAppointmentsFailed =>
        resolve({ error }),
  ),
};
