import { createReducer } from 'deox';

import { TCreateAppointmentResponse, TGetAppointmentsResponse } from '@/services/api/appointment';
import { createAppointmentAction, getAppointmentsAction } from '@/redux/actions';
import { createAppointmentUpdateState } from './create-appointment';
import { getAppointmentsUpdateState } from './get-appointments';

export type TAppointmentState = {
  createAppointmentResponse?: TCreateAppointmentResponse;
  getAppointmentsResponse?: TGetAppointmentsResponse;
};

const initialState: TAppointmentState = {
  createAppointmentResponse: undefined,
  getAppointmentsResponse: undefined,
};

const AppointmentReducer = createReducer(initialState, (handleAction) => [
  handleAction(createAppointmentAction.success, createAppointmentUpdateState),
  handleAction(getAppointmentsAction.success, getAppointmentsUpdateState),
]);

export default AppointmentReducer;
