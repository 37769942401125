import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getShopsAction } from '@/redux/actions';
import { getShops, TGetShopsResponse } from '@/services/api';

// FUNCTION

export function* getShopsSaga(action: ActionType<typeof getShopsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getShops, materials);
    const getShopsResponse: TGetShopsResponse = response as TGetShopsResponse;
    yield put(getShopsAction.success(getShopsResponse));
    successCallback?.(getShopsResponse);
  } catch (err) {
    yield put(getShopsAction.failure(err));
    failedCallback?.(err);
  }
}
