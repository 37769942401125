import { createReducer } from 'deox';

import { TGetBranchesByShopResponse, TGetShopsResponse } from '@/services/api/shop';
import { getBranchesByShopAction, getShopsAction } from '@/redux/actions';
import { getBranchesByShopUpdateState } from './get-branches-by-shop';
import { getShopsUpdateState } from './get-shops';

export type TShopState = {
  getBranchesByShopResponse?: TGetBranchesByShopResponse;
  getShopsResponse?: TGetShopsResponse;
};

const initialState: TShopState = {
  getBranchesByShopResponse: undefined,
  getShopsResponse: undefined,
};

const ShopReducer = createReducer(initialState, (handleAction) => [
  handleAction(getBranchesByShopAction.success, getBranchesByShopUpdateState),
  handleAction(getShopsAction.success, getShopsUpdateState),
]);

export default ShopReducer;
