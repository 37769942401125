import { createActionCreator } from 'deox';

import { TGetTechniciansMaterials, TGetTechniciansResponse } from '@/services/api/technician/get-technicians';

// CONSTANTS

export enum EGetTechniciansAction {
  GET_TECHNICIANS = 'GET_TECHNICIANS',
  GET_TECHNICIANS_REQUEST = 'GET_TECHNICIANS_REQUEST',
  GET_TECHNICIANS_SUCCESS = 'GET_TECHNICIANS_SUCCESS',
  GET_TECHNICIANS_FAILED = 'GET_TECHNICIANS_FAILED',
}

// TYPES

export type TGetTechniciansRequest = {
  type: EGetTechniciansAction.GET_TECHNICIANS_REQUEST;
  payload: {
    materials: TGetTechniciansMaterials;
    successCallback?: (response: TGetTechniciansResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetTechniciansSuccess = {
  type: EGetTechniciansAction.GET_TECHNICIANS_SUCCESS;
  payload: { response: TGetTechniciansResponse };
};

export type TGetTechniciansFailed = { type: EGetTechniciansAction.GET_TECHNICIANS_FAILED };

// FUNCTION

export const getTechniciansAction = {
  request: createActionCreator(
    EGetTechniciansAction.GET_TECHNICIANS_REQUEST,
    (resolve) =>
      (
        materials: TGetTechniciansMaterials,
        successCallback?: (response: TGetTechniciansResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetTechniciansRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetTechniciansAction.GET_TECHNICIANS_SUCCESS,
    (resolve) =>
      (response: TGetTechniciansResponse): TGetTechniciansSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetTechniciansAction.GET_TECHNICIANS_FAILED,
    (resolve) =>
      (error: unknown): TGetTechniciansFailed =>
        resolve({ error }),
  ),
};
