import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TGuestProps } from '@/layouts/Guest/Guest.types';
import Header from '@/containers/Header';
import Logo from '@/assets/images/logo.png';
import { getSettingsAction } from '@/redux/actions';
import { TRootState } from '@/redux/reducers';
import { getFullUrlStatics } from '@/utils/functions';

import './Guest.scss';

const Guest: React.FC<TGuestProps> = ({ children }) => {
  const dispatch = useDispatch();

  const settingsState = useSelector((state: TRootState) => state.settingReducer.getSettingsResponse)?.data;

  const getSettings = useCallback(() => {
    dispatch(getSettingsAction.request({}));
  }, [dispatch]);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <div className="Guest">
      <div className="Guest-background">
        <div className="Guest-background-cover">
          {settingsState?.business?.background_image && (
            <img src={getFullUrlStatics(settingsState?.business?.background_image)} alt="" />
          )}
          <div className="Guest-background-overlay-blur" style={{ backdropFilter: 'blur(4px)' }} />
        </div>

        <div className="Guest-background-thumbnail">
          {settingsState?.business?.background_image && (
            <img src={getFullUrlStatics(settingsState?.business?.background_image)} alt="" />
          )}
          <div className="Guest-background-overlay-blur" />
        </div>

        <div className="Guest-background-overlay" />
      </div>

      <div className="Guest-header">
        <Header />
      </div>

      <div className="Guest-body">{children}</div>
      <div className="Guest-footer">
        <div className="Guest-footer-text">powered by</div>
        <div className="Guest-footer-logo">
          <img src={Logo} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Guest;
