import { all, takeLatest } from 'redux-saga/effects';

import { getBranchesByShopAction, getShopsAction } from '@/redux/actions';

import { getBranchesByShopSaga } from './get-branches-by-shop';
import { getShopsSaga } from './get-shops';

export default function* root(): Generator {
  yield all([
    takeLatest(getBranchesByShopAction.request.type, getBranchesByShopSaga),
    takeLatest(getShopsAction.request.type, getShopsSaga),
  ]);
}
